<template>
  <div class="dilu-page" style="background-color:#f4f6f9;">
    <div class="statistics">
      <div class="cumulative-ul">
        <div class="cumulative-li">
          <div class="cumulative-li-left">
            <span>平台项目数</span>
            <span><countTo :startVal="0" :endVal="projTotal" :duration="3000"/></span>
          </div>
          <div class="cumulative-li-right">
            <img src="../../assets/images/statistics-icon1.png" style="width:28px;height:28px;"/>
          </div>
        </div>
        <div class="cumulative-li">
          <div class="cumulative-li-left">
            <span>平台设备数</span>
            <span><countTo :startVal="0" :endVal="devTotal" :duration="3000"/></span>
          </div>
          <div class="cumulative-li-right">
            <img src="../../assets/images/statistics-icon2.png" style="width:32px;height:32px;"/>
          </div>
        </div>
        <div class="cumulative-li">
          <div class="cumulative-li-left">
            <span>在线设备数</span>
            <span><countTo :startVal="0" :endVal="devOnline" :duration="3000"/></span>
          </div>
          <div class="cumulative-li-right">
            <img src="../../assets/images/statistics-icon3.png" style="width:32px;height:32px;"/>
          </div>
        </div>
        <div class="cumulative-li">
          <div class="cumulative-li-left">
            <span>平台网关数</span>
            <span> <countTo :startVal="0" :endVal="gwTotal" :duration="3000"/></span>
          </div>
          <div class="cumulative-li-right">
            <img src="../../assets/images/statistics-icon4.png" style="width:32px;height:32px;"/>
          </div>
        </div>
        <div class="cumulative-li">
          <div class="cumulative-li-left">
            <span>在线网关数</span>
            <span> <countTo :startVal="0" :endVal="gwOnline" :duration="3000"/></span>
          </div>
          <div class="cumulative-li-right">
            <img src="../../assets/images/statistics-icon5.png" style="width:28px;height:28px;"/>
          </div>
        </div>
      </div>
      <div class="distribution-add">
        <div class="distribution module">
          <div class="distribution-content">
            <div id="distribution-map"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import remoteLoad from "@/assets/js/remoteLoad";

export default {
  data() {
    return {
      center: [116.402921, 39.908177],
      map: null,
      mapList: [],
      projTotal: 0,
      devTotal: 0,
      devOnline: 0,
      gwTotal: 0,
      gwOnline: 0
    };
  },
  mounted() {
    Promise.all([
      remoteLoad(
        "https://webapi.amap.com/maps?v=1.4.15&key=77faf27c291ef4d7a20bfb8d5f74b0b4&plugin=AMap.Riding,AMap.PolyEditor"
      ),
    ]);
    this.tenantHome();
  },
  computed: {
    
  },
  components: {
    countTo
  },
  methods: {
    tenantHome() {
      var params={};
      this.request.tenantHome(params).then((res) => {
        if (res.code == 0) {
          this.projTotal=res.data.projTotal;
          this.devTotal=res.data.devTotal;
          this.devOnline=res.data.devOnline;
          this.gwTotal=res.data.gwTotal;
          this.gwOnline=res.data.gwOnline;
          let gpsList = res.data.gpsList;
          let items = [];
          for (let i in gpsList) {
            if (gpsList[i]!=null&&gpsList[i].split(",").length>1) {
              items.push([
                gpsList[i].split(",")[1],
                gpsList[i].split(",")[0],
              ]);
            }
          }
          this.mapList = items;
          setTimeout(()=>{
            this.initMap();
          },500);
        }
      });
    },
    initMap() {
      let that = this;
      let positions = that.mapList;
      let center = [];
      if (positions.length < 1) {
        center = [115.299,35.568];
      } else {
        center = [Number(positions[0][0]), Number(positions[0][1])];
      }
      var map = new AMap.Map("distribution-map", {
        zoom: 4,
        showLabel: false,
        expandZoomRange: true,
        center: center,
        pitch: 60,
        mapStyle: "amap://styles/398469a1eaea0a930c1cb33226ee3080", //设置地图的显示样式
      });
      map.on("complete", function () {
        let arr = [];
        for (var i = 0; i < positions.length; i++) {
          var curPosition = positions[i];
          arr[i] = new AMap.CircleMarker({
            center: curPosition,
            radius: 10 + Math.random() * 3, //3D视图下，CircleMarker半径不要超过64px
            strokeColor: "transparent",
            strokeWeight: 1,
            fillColor: "#1F5BCC",
            fillOpacity: 0.5,
            zIndex: 10,
            bubble: true,
            cursor: "pointer",
            clickable: true,
          });
          arr[i].setMap(map);
        }
      });
    },
  }
};

</script>

<style scoped>
.statistics {
  padding: 25px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.cumulative-ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.cumulative-li {
  width: calc(20% - 25px);
  height: 120px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #f0f3fa;
  border-radius: 6px;
}
.cumulative-li-left {
  display: flex;
  flex-direction: column;
}
.cumulative-li-left span:first-child {
  font-size: 14px;
  font-weight: normal;
  color: #7685a9;
  margin-bottom: 12px;
}
.cumulative-li-left span:last-child {
  font-size: 28px;
  font-family: DIN;
  font-weight: bold;
  color: #000000;
}
.cumulative-li-right {
  width: 40px;
  flex-shrink: 0;
}
.cumulative-li-right img {
  width: 100%;
}
.distribution-add {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.distribution {
  width: 100%;
  height: 630px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #f0f3fa;
  opacity: 1;
  border-radius: 6px;
}
.add {
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 3px 6px #f0f3fa;
  opacity: 1;
  border-radius: 6px;
}
.module {
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.distribution-content {
  flex: 1;
  padding: 0 10px;
  box-sizing: border-box;
}
#distribution-map {
  width: 100%;
  height: 100%;
}
.header-region {
  background: rgba(210, 222, 244, 0.35);
  border-radius: 15px;
  padding: 5px 20px;
  box-sizing: border-box;
}
.add-content {
  flex: 1;
}
.riding-mileage {
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #f0f3fa;
  border-radius: 6px;
  margin-bottom: 25px;
  flex-shrink: 0;
}
</style>